import { Switch, Route } from 'react-router-dom';
import routes from './routes';

const renderRoute = (routeArr, props, parent = '') => {

    var additionalArr = [];
    
    var completeArr = routeArr.map((route, i) => {
        var renderArr = <Route path={parent + route.path} exact={route.exact} key={route.key}>{route.component(props)}</Route>
        if(route.routes !== undefined){
            var subArr = renderRoute(route.routes, props, parent + route.path);
            additionalArr = [...additionalArr, ...subArr]
        }

        return renderArr;
    })
    completeArr = [...completeArr, ...additionalArr]
    return completeArr;
}

export default function Router(props){
    return (
        <Switch>
            {renderRoute(routes, props)}
        </Switch>
    )
}