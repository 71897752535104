import NavBar from '../Navigation/NavBar';
import NavLeft from '../Navigation/NavLeft';
import { makeStyles} from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    content:{
        position: "absolute",
        top: "64px",
        left: "225px",
        height: "calc(100% - 64px)",
        width: "calc(100% - 225px)"
    },
    gridWrapper: {
        height: "100%",
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    root: {
        height: "100%",
        maxHeight: "100%",
        flexGrow: 1,
        overflowY: "auto",
        position: "relative",
    }
}));

export default function Layout (props){
    
    const classes = useStyles();

    return (
        <BrowserRouter>
            <div className={classes.root}>
                <NavBar />
                <NavLeft />
                <div className={classes.content}>
                    {props.children}
                </div>
            </div>
        </BrowserRouter>
    )
}