import { useLayoutEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import axios from 'axios';

import { Container, Grid, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);

const useStyles = makeStyles((theme) => ({
    wrapperGrid: {
        padding: 10,
    },
}));

export default function ImplementationTask(props){

    const classes = useStyles();

    const [ categories, setCategories ] = useState([{}]);
    let { url } = useRouteMatch();

    useLayoutEffect(() => {
        axios.get('http://client.eziil.com/api/task_categories.json?select=get_done_tasks()@a', {
            withCredentials: true
        })
        .then(res => setCategories(res.data.map(cat => cat.a)))
    }, [])

    return (
        <Container maxWidth={false}>
            <Grid container className={classes.wrapperGrid}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h4">Implementation Todo</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="todo-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Task Category</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>Progress</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { categories.map(cat => (
                                    <TableRow key={`cat-${cat.id}`} component={Link} to={`${url}/${cat.id}`} hover={true}>
                                        <TableCell>{cat.category_name}</TableCell>
                                        <TableCell>{cat.mandatory ? "Kohustuslik" : (cat.recommended ? 'Soovituslik' : null)}</TableCell>
                                        <TableCell><BorderLinearProgress variant="determinate" value={cat.done_precentage ?? 0} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
    )
}