import { Container, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapperGrid: {
        padding: 10
    },
}));

export default function Implementation(props){

    const classes = useStyles();

    return (
        <Container maxWidth={false}>
            <Grid container className={classes.wrapperGrid}>
                <Typography variant="h5" component="h4">Implementation</Typography>
            </Grid>
        </Container>
    )
}