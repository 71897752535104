import HomePage from '../pages/HomePage/HomePage';
import Implementation from '../pages/Implementation/Implementation';
import ImplementationTask from '../pages/Implementation/ImplementationTask';
import Task from '../pages/Implementation/Task';

import Home from '@material-ui/icons/Home';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const ROUTES = [
    {
        name: "Home",
        path: "/", 
        key: "ROOT", 
        exact: true,
        icon: <Home />,
        component: (props) => <HomePage user={props.user} />
    },
    {
        name: "Implementation",
        path: "/implementation",
        key: "implementation",
        exact: true,
        icon: <SettingsApplicationsIcon />,
        component: (props) => <Implementation {...props}/>,
        routes: [{
            name: "Todo",
            path: "/todo",
            key: "imp-todo-list",
            exact: true,
            component: (props) => <ImplementationTask {...props} />,
            routes: [{
                path: "/:task",
                key: "imp-tasks",
                exclude: true,
                component: (props) => <Task {...props} />
            }]
        }]
    },
    {
        name: "Sales",
        path: "/sales",
        key: "sales",
        exact: true,
        icon: <AttachMoneyIcon />,
        component: (props) => (<div><p>Sales Page!</p></div>)
    }
]

export default ROUTES;