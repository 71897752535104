import { useEffect, useState } from 'react'
import { checkAuth } from './controllers/Auth';
import LoginPage from './pages/LoginPage/LoginPage';
import Router from './routes/router';
import Layout from './views/Layout/Layout';
import "./App.css";

import { ThemeProvider, createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "rgba(132,220,207,1)",
        },
        secondary:{
            main: "rgba(46.134.171,1)",
        }
    }
})

function App(){

    const [ auth, setAuth ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ user, setUser ] = useState(0);

    useEffect(() => {
        (async function(){
            var auth = await checkAuth();

            if(auth.success){
                setAuth(true)
                setUser(auth.user)
                setLoading(false);
            } else {
                setAuth(false);
                setLoading(false);
            }
        })();
    }, [])
    if(loading){
        return null;
    }

    if(auth){
        return (
            <ThemeProvider theme={theme}>
                <Layout>
                    <Router user={user}></Router>
                </Layout>
            </ThemeProvider>
        )
    } else {
        return (
            <ThemeProvider theme={theme}>
                <LoginPage />
            </ThemeProvider>
        )
    }


}

export default App