import { login } from "../../controllers/Auth";
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';

import "./LoginPage.css";

export default function LoginPage (){
    
    return (
        <div id="loginPage">
            <form id="loginForm" onSubmit={(e) => login(e)}>
                <Grid height="100%" container spacing={1} direction="column" justify="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h4">Login</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="username" label="Username"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="password" type="password" label="Password" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="database" label="Database" />
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt="20px">
                            <Button type="submit" variant="contained" color="primary">Login</Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}