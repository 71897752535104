import ListItemLink from "./ListItemLink";

import { Collapse, makeStyles } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4)
    }
}));

export default function ListItemLinkCollapse(props){

    const classes = useStyles();
    const renderCollapse = () => {

        var subItems = props.subCat.map((subCat, index) => {
            if(!subCat.exclude){
                return <ListItemLink className={classes.nested} primary={subCat.name} icon={subCat.icon ? subCat.icon : <RemoveIcon />} key={"sub-"+index} to={props.item.path + subCat.path}/>
            } else {
                return null;
            }
        })

        return (
            <Collapse in={props.open} timeout="auto" unmountOnExit>
                {subItems}
            </Collapse>
        )
    }

    return (
        <li>
            <ListItemLink primary={props.item.name} icon={props.item.icon ? props.item.icon : null} to={props.item.path} {...props}>
                {props.open ? <ExpandLess /> : <ExpandMore />}
            </ListItemLink>
            {renderCollapse()}
        </li>
    );

}