import React from 'react';

import ListItemLink from '../../components/List/ListItemLink';
import ListItemLinkCollapse from '../../components/List/ListItemLinkCollapse';
import routes from '../../routes/routes';

import { List, ListSubheader, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    leftNav:{
        height: "calc(100% - 64px)",
        maxWidth: 225,
        backgroundColor: "rgba(49,47,47,0.09)",
        overflow: "hidden",
        position: "sticky",
        top: "64px",
        [theme.breakpoints.down('sm')]:{
            top: "56px",
            height: "calc(100% - 56px)"
        }
    },
}));

export default function NavLeft(){

    const classes = useStyles();
    const [open, setOpen] = React.useState({});

    const handleClick = (id) => {
        setOpen(prevState => ({...prevState, [id]: !prevState[id]}))
    }

    return (
            <div className={classes.leftNav}>
                <List component="nav" subheader={<ListSubheader component="div">NAVIGATION</ListSubheader>}>
                    {routes.map((item, index) => {
                            if(item.routes){
                                return <ListItemLinkCollapse open={open[index]} subCat={item.routes} item={item} key={item.key} parentIndex={index} onClickFunction={handleClick} />;
                            } else {
                                return <ListItemLink primary={item.name} icon={(item.icon) ? item.icon : null} key={index} to={item.path}/>
                            }
                    })}
                </List>
            </div>
    )
}