import { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { Checkbox ,Container, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapperGrid: {
        padding: 10,
    },
}));

export default function Task(props){

    const classes = useStyles();
    const { task } = useParams();
    const [ tasks, setTasks ] = useState([]);
    const [ doneList, setDoneList ] = useState([]);

    useLayoutEffect(() => {
        axios.get(`http://client.eziil.com/api/tasks_with_done_list.json?where=task_category_id=${task}`, {
            withCredentials: true,
        })
        .then(res => {setTasks(res.data); setDoneList(res.data[0]?.done_task_ids ?? [])})
    }, [task])
    
    const addTaskDone = (task_id, list_id) => {
        var tempArr = [...doneList];
        if(!tempArr.includes(task_id)){
            tempArr.push(task_id)
            var postData = [{
                category_id: task,
                done_task_ids: `{${tempArr}}`,
                update: true,
                entry_id: list_id
            }]
            setDoneList(tempArr)
            axios.post('http://client.eziil.com/api/task_done_list.json', postData, {withCredentials: true})
        }
    }

    const removeTaskDone = (task_id, list_id) => {
        var tempArr = doneList.filter(a => a !== task_id);
        var postData = [{
            category_id: task,
            done_task_ids: `{${tempArr}}`,
            update: true,
            entry_id: list_id
        }]
        setDoneList(tempArr)
        axios.post('http://client.eziil.com/api/task_done_list.json', postData, {withCredentials: true})
    }

    console.log(tasks);
    return (
        <Container maxWidth={false}>
            <Grid container className={classes.wrapperGrid}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h4">Ülesanded</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="todo-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Ülesande nimetus</TableCell>
                                    <TableCell>Eeldatav ajakulu</TableCell>
                                    <TableCell>Videod</TableCell>
                                    <TableCell>Manuaalid</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { tasks.map( item => (
                                    <TableRow hover={true} key={`tr-${item.id}`}>
                                        <TableCell><Checkbox value={item.id} checked={doneList.includes(item.id)} onChange={() => {(doneList.includes(item.id)) ? removeTaskDone(item.id, item.done_list_id) : addTaskDone(item.id, item.done_list_id)}}/></TableCell>
                                        <TableCell>{item.task_name}</TableCell>
                                        <TableCell>{item.estimated_time_consumption}</TableCell>
                                        <TableCell><a target="_blank" href={item.additional_material?.video ?? null} rel="noreferrer">{item.additional_material?.video ? 'Video' : null}</a></TableCell>
                                        <TableCell><a target="_blank" href={item.additional_material?.manual ?? null} rel="noreferrer">{item.additional_material?.manual ? 'Manual' : null}</a></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
    )
}