import axios from 'axios';
import md5 from 'md5';

export async function checkAuth(){

    var obj = axios.get('http://client.eziil.com/api/fake.auth')
    .then(res => {
        if(res.data.error !== undefined){
            return {
                error: res.data.error,
                success: false
            }
        } else {
            return {
                user: res.data.user,
                success: true
            }
        }
    });

    return obj;
}

export async function login(e){
    e.preventDefault();

    var username = e.target.username.value,
        password = md5(e.target.password.value),
        database = e.target.database.value ?? 'eziil_kontor';
    
    var login = axios.get(`http://client.eziil.com/api/fake.auth?user=${username}&pass=${password}&database=${database}&select=check_auth(user,pass,database)`,{
        withCredentials: 'include'
    })
    .then(res => {
        console.log(res);
        if(res.user){
            window.location.reload(true);
            return true;
        } else {
            if(!res.user){
                axios.get(`http://client.eziil.com/api2/eziilAuth?username=${username}&password=${md5(password)}&database=${database}`)
                .then(res2 => {
                    if(res2.error){
                        alert(res2.error);
                    } else {
                        window.location.reload(true);
                        return true;
                    }
                })
            } else {
                alert(res.error)
            }
        }
    })

    return login;
}

export async function logout(e){
    e.preventDefault();

    axios.get('http://client.eziil.com/api/fake.auth?logout=true')
    .then(res => window.location.reload(true))
}