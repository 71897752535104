import { forwardRef, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

export default function ListItemLink(props){
    const { icon, primary, to } = props;
    console.log(to);
    const renderLink = useMemo(
        () => forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    return (
        <ListItem className={props.className} button component={renderLink} onClick={(props.onClickFunction !== undefined) ? () => props.onClickFunction(props.parentIndex) : null}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
          {props.children}
        </ListItem>
    );

}