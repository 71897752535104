import React from 'react';

import { logout } from '../../controllers/Auth'

import { AppBar, IconButton, makeStyles, Menu, MenuItem, Toolbar } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "rgba(49,47,47,1)",
    },
    logoWrapper:{
        flexGrow: 1,
    },
    logo: {
      maxWidth: "50px"
    },
  }));

export default function NavBar(){
    
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar className={classes.appBar} position="sticky">
            <Toolbar>
                <div className={classes.logoWrapper}>
                    <img src="/images/eziil-logo-only-00abd.png" alt="Eziil logo!" className={classes.logo}/>
                </div>
                <div>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle style={{fill: "white"}} />
                    </IconButton>
                    <Menu
                        color="white"
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                        <MenuItem onClick={logout}>Logout</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    )
}